import { BrowserRouter, Routes, Route, Link } from "react-router-dom"

// pages
import Home from "./pages/Home"
import About from "./pages/About"


function App() {
  return (
    <BrowserRouter>
      { <nav>
          <Link to="/">Home</Link>
          <Link to="/About">About</Link>
      </nav> }
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

// remove from nav under h1: <Link to="/">Home</Link>
// remove from nav: <Link to="/create">Create New Product</Link>